.circle-container {
    display: flex;
    justify-content: space-between; /* Ensures equal spacing between circles */
    align-items: flex-start; /* Aligns items at the top */
    background-color: white; /* Set the background of the container to white */
    margin: 40px;
    padding: 40px; /* Optional: Adds padding around the container */
    border-radius: 8px; /* Optional: Rounded corners around the container */
}

.circle-label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensures the circle and label are horizontally aligned */
    text-align: center;
}

.circle {
    width: 50px; /* Diameter of the circle */
    height: 50px; /* Diameter of the circle */
    border-radius: 50%; /* Makes the div circular */
}

.circle-label {
    margin-top: 8px; /* Space between the circle and the label */
    font-size: 14px; /* Adjust font size for readability */
    text-align: center;
    color: black; /* Make the label text white */
    font-weight: bold; /* Optional: Make the label text bold */
}