@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.LoginPage {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}

@media screen and (min-width: 768px) {
  .HomeImageDesktop {
    object-fit: contain;
    overflow: hidden;
    width: 100%;  
  }
  .HomeImageMobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .HomeImageDesktop {
    display: none;
  }
  .HomeImageMobile {
    object-fit:contain;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

.Names {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 400;
  font-optical-sizing: auto;

  position: absolute;
  left: 10%;
  bottom: 5%;
  font-size: 8vw;
  color: #f6f7ec;
  text-align: left;
  line-height: 90%;
  max-width: 700px;
}

.menu-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

@media (min-width: 768px) {
  .menu-container {
    display: none;
  }
}

@media (min-width: 768px) {
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px;
  }
}

.hamburger {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger-menu {
  position: absolute;
  top: 40px;
  left: 10px;
  background-color: rgba(246, 247, 236, 0.8);
  border: 1px solid #f6f7ec;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hamburger-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.hamburger-menu li {
  padding: 10px 20px;
  cursor: pointer;
  text-transform: capitalize;
}

.hamburger-menu li:hover {
  background-color: rgba(43, 0, 77, 0.8);
  color: #f6f7ec;
}

.button-container {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10;
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .button-container {
    display: none;
  }
}

.hamburger,
.logout-button {
  font-size: 24px;
  background-color: rgba(246, 247, 236, 0.8);
  color: black;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1000;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.hamburger:hover,
.logout-button:hover {
  background-color:  #f6f7ec;
  transform: scale(1.1);
}

.language-toggle {
  font-size: 26px;
  background-color: rgba(246, 247, 236, 0.8);
  color: black;
  border: none;
  border-radius: 50px;
  padding: 10px 15px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease, width 0.3s ease;
  white-space: nowrap;
  position: relative;
  z-index: 1000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.language-toggle:hover {
  transform: scale(1.1);
}
