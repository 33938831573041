.HomeImage, .HomeImageDesktop, .HomeImageMobile {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
    margin: auto; 
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .HomeImage {  
        background-image: url('../img/home.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
  }
  
@media screen and (max-width: 768px) {
    .HomeImage {
        background-image: url('../img/home-small.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
  }


@media screen and (min-width: 768px) {
    .HomeImageDesktop {  
      margin: auto;
      max-width: 100%;
    }
    .HomeImageMobile {
      display: none;
    }
  }
  
@media screen and (max-width: 768px) {
    .HomeImageDesktop {
      display: none;
    }
    .HomeImageMobile {
        margin: auto;
        max-width: 100%;
    }
  }

.main-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.component-container {
    position: relative;
    z-index: 1;
}

.scrolling-container {
    position: relative;
    height: auto;
    overflow: auto;
}