.SiteActive-Container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f6f7ec;
  min-height: 100vh;
}

.Logo{
  margin: auto; 
  max-width: 100%; 
  margin-top: 10%;
}

.ComingSoon {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .ComingSoon {
    font-size: x-large;
  }
}

@media screen and (max-width: 600px) {
  .ComingSoon {
    font-size: larger;
  }
}