.container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust as needed */
    overflow: hidden;
}

.HomeImageDesktop, .HomeImageMobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Adjust this if you need the images to cover the container */
    z-index: -1; /* This ensures the images are behind the form */
}

.HomeImageMobile {
    display: none; /* You can handle mobile-specific styling here */
}

.form-container {
    position: relative;
    z-index: 1; /* This ensures the form is above the images */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 90vh;
    opacity: 90%;
    overflow-y: auto;
    padding: 75px;
}

@media (max-width: 768px) {
    .HomeImageDesktop {
        display: none;
    }
    .HomeImageMobile {
        display: block;
    }
}